import React from "react";
import AliceCarousel from "react-alice-carousel";
import { useTranslation } from "react-i18next";

export const About: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const featureItems: string[] = Object.values(
    t("about.feature.items", {
      returnObjects: true,
    })
  );

  const images = [
    "assets/img/about/Project Eletrical.png",
    "assets/img/about/Project Grafcet.png",
    "assets/img/about/Project Manual.png",
    "assets/img/about/Project Schematics.png",
    "assets/img/about/Project Software.png",
    "assets/img/about/Project Test.png",
  ];

  const items = images.map((c) => (
    <div className="item" data-value={c}>
      <img
        src={c}
        alt={"automation project for industries"}
        className="img-fluid mb-3 mb-lg-0"
        style={{ borderRadius: "10px" }}
      />
    </div>
  ));

  return (
    <>
      <section className="about-section text-center" id="about">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8">
              <h2 className="text-white mb-4">{t("about.title")}</h2>
              <p className="text-white-50">{t("about.text")}</p>
            </div>
          </div>
          <img
            className="img-fluid"
            src="assets/img/ipad.png"
            alt="ipad with website view"
          />
        </div>
      </section>
      <section className="projects-section bg-light">
        <div className="container px-4 px-lg-5">
          <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
            <div className="col-xl-6 col-lg-5">
              <AliceCarousel
                autoPlay
                autoPlayStrategy="none"
                autoPlayInterval={1000}
                animationDuration={1000}
                animationType="fadeout"
                infinite
                touchTracking={false}
                disableDotsControls
                disableButtonsControls
                items={items}
              />
            </div>
            <div className="col-xl-6 col-lg-5">
              <div className="text-lg-left featured-text ">
                <h4
                  className="text-black mb-4 "
                  style={{ textAlign: "center" }}
                >
                  {t("about.feature.title")}
                </h4>

                <ul>
                  {featureItems?.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 px-lg-5 "></div>
      </section>
    </>
  );
};
