import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { Customer, Employee } from "./types";

const url = "https://areus.pt/areus/areus-api/public";

// default cache 15 minutes
const cache = setupCache({});

const api = axios.create({
  adapter: cache.adapter,
  headers: {
    "Content-Type": "application/json",
  },
});

export class ApiHelper {
  /**
   * Method to Get All Customers
   * @returns Customer[]
   */
  public static getAllCustomers = async (): Promise<Customer[]> => {
    let data: Customer[] = [];
    await api.get(`${url}/getCustomers`).then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        data = res.data.data || [];
      }
    });
    return data;
  };

  /**
   * Method to Get All Employees
   * @returns Employee[]
   */
  public static getAllEmployees = async (): Promise<Employee[]> => {
    let data: Employee[] = [];
    await api.get(`${url}/getEmployees`).then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        data = res.data.data || [];
      }
    });
    return data;
  };
}
