import React from "react";
import { useTranslation } from "react-i18next";
import { Master } from "../../components";

export const Projects: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Master isHome={false}>
      <section className="screen bg-light" id="services">
        <div className="container px-4 px-lg-5 ">
          <h2 className="text-black mb-4" style={{ textAlign: "center" }}>
            {t("projects.title")}
          </h2>
        </div>
      </section>
    </Master>
  );
};
