import React from "react";
import { useTranslation } from "react-i18next";
import { ApiHelper, Employee as IEmployee } from "../../helper";
import people from "../../assets/images/people.png";

export const Employee: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const [employees, setEmployees] = React.useState<IEmployee[]>([]);

  const getAllEmployees = async (): Promise<void> => {
    const response = await ApiHelper.getAllEmployees();
    setEmployees(response);
  };

  React.useEffect((): void => {
    getAllEmployees();
  }, []);

  return (
    <>
      <section className="projects-section bg-light" id="team">
        <div className="container px-4 px-lg-5 ">
          <h2
            className="text-black mb-4"
            style={{ textAlign: "center", paddingBottom: "50px" }}
          >
            {t("employee.title")}
          </h2>
          <div className="row">
            {employees.length > 0 &&
              employees.map((employee, index) => {
                const media = 12 / employees.length;

                return (
                  <div
                    className={`col-sm-${media} justify-content-center align-items-center`}
                  >
                    <div className="justify-content-center text-center">
                      <img
                        className="img-fluid"
                        src={people}
                        alt={`Our employer ${employee.name}`}
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                    <div>
                      <div className="text-center h-100 project">
                        <div className="d-flex ">
                          <div className="project-text w-100 my-auto text-center">
                            <h4 className="text-black">{employee.name}</h4>
                            <p className="mb-0 text-black-50">
                              <p>{employee.responsibility}</p>
                              <p>
                                <a
                                  href={`mailto:${employee.email}`}
                                  style={{
                                    fontSize: "14px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {employee.email}
                                </a>
                              </p>
                              <p>
                                <a
                                  href={`tel:${employee.phone}`}
                                  style={{
                                    fontSize: "14px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {employee.phone}
                                </a>
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};
