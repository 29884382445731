import React from "react";
import { useTranslation } from "react-i18next";
import { ApiHelper, Customer } from "../../helper";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export const Customers: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const [customers, setCustomers] = React.useState<Customer[]>([]);

  const getAllCustomers = async (): Promise<void> => {
    const response = await ApiHelper.getAllCustomers();
    setCustomers(response);
  };

  React.useEffect((): void => {
    getAllCustomers();
  }, []);

  const items = customers.map((c) => (
    <div
      className="item"
      data-value={c.image}
      style={{
        height: "150px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img width="150" src={c.image} alt={`Our Customer ${c.name}`} />
    </div>
  ));

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    <>
      <section className="bg-light" id="team" style={{ paddingBottom: "3rem" }}>
        <div className="container px-4 px-lg-5 ">
          <h2
            className="text-black mb-4"
            style={{ textAlign: "center", paddingBottom: "50px" }}
          >
            {t("customers.title")}
          </h2>
          <div style={{ textAlign: "center" }}>
            <AliceCarousel
              autoPlay
              autoPlayStrategy="none"
              autoPlayInterval={1000}
              animationDuration={1000}
              animationType="fadeout"
              infinite
              touchTracking={false}
              disableDotsControls
              disableButtonsControls
              items={items}
              responsive={responsive}
            />
          </div>
        </div>
      </section>
    </>
  );
};
