import React from "react";
import AliceCarousel from "react-alice-carousel";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { About, Customers, Employee, Master } from "../../components";

export const Home: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onClickSeeMoreProjects = React.useCallback((): void => {
    navigate("/projects");
  }, [navigate]);

  const images = [
    "assets/img/project/11 - Project Overall.png",
    "assets/img/project/4 HMI 2.png",
    "assets/img/project/2 - Strapping Machine.png",
    "assets/img/project/1 Project Overall.png",
    "assets/img/project/1 Home.png",
    "assets/img/project/1 Project.jpeg",
    "assets/img/project/1 Home 2.png",
  ];

  const items = images.map((c) => (
    <div className="item" data-value={c}>
      <img
        src={c}
        alt={"automation project for industries"}
        className="img-fluid mb-3 mb-lg-0"
        style={{ borderRadius: "10px" }}
      />
    </div>
  ));

  return (
    <Master>
      <header className="masthead">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <h1 className="mx-auto my-0 text-uppercase">
                <Trans t={t}>{t("welcome.title")}</Trans>
              </h1>
              <h2 className="text-white-50 mx-auto mt-2 mb-5">
                {t("welcome.text")}
              </h2>
            </div>
          </div>
        </div>
      </header>
      <About />
      <section className="projects-section bg-light" id="services">
        <div
          className="container px-4 px-lg-5 text-center"
          style={{ textAlign: "center" }}
        >
          <h2 className="text-black mb-4" style={{ paddingBottom: "50px" }}>
            {t("projects.title")}
          </h2>
          <div
            className="row gx-0 mb-4 mb-lg-5 align-items-center flex-column-reverse flex-lg-row"
            style={{ justifyContent: "space-between" }}
          >
            <div className="col-xl-6 col-lg-5">
              <img
                src="assets/img/automationareus_qr.png"
                alt="Areus QR Code to access Instagram"
              ></img>
              <p>
                Check or projects in Instagram or click{" "}
                <a
                  href="https://www.instagram.com/automationareus/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </p>
            </div>
            <div className="col-xl-6 col-lg-5">
              <AliceCarousel
                autoPlay
                autoPlayStrategy="none"
                autoPlayInterval={2000}
                animationDuration={1000}
                animationType="fadeout"
                infinite
                touchTracking={false}
                disableDotsControls
                disableButtonsControls
                items={items}
              />
            </div>
          </div>
          {/* We are working to show you our projects! Stay tuned! */}
          {/* <div className="col-auto">
            <button
              className="btn btn-primary"
              id="submitButton"
              onClick={onClickSeeMoreProjects}
            >
              {t("projects.button.seeMore")}
            </button>
          </div> */}
        </div>
      </section>
      <Employee />
      <Customers />
    </Master>
  );
};
