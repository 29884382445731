import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/images/AREUS-NO-BACKGROUND.png";

export interface MasterProps {
  isHome?: boolean;
}

export const Master: React.FC<MasterProps> = ({ children, isHome = true }) => {
  const { t } = useTranslation();

  React.useEffect((): void => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top"
        id="mainNav"
      >
        <div className="container px-4 px-lg-5">
          {isHome ? (
            <a className="navbar-brand" href="#page-top">
              <img
                src={logo}
                alt="Areus Logo"
                className="img-fluid"
                style={{ maxWidth: "150px" }}
              />
            </a>
          ) : (
            <Link className="navbar-brand" to="/">
              <img
                src={logo}
                alt="Areus Logo"
                className="img-fluid"
                style={{ maxWidth: "150px" }}
              />
            </Link>
          )}
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                {isHome ? (
                  <a className="nav-link" href="#about">
                    {t("nav.about")}
                  </a>
                ) : (
                  <Link className="nav-link" to="/">
                    {t("nav.about")}
                  </Link>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  {t("nav.services")}
                </a>
              </li>
              <li className="nav-item">
                {isHome ? (
                  <a className="nav-link" href="#team">
                    {t("nav.team")}
                  </a>
                ) : (
                  <Link className="nav-link" to="/">
                    {t("nav.team")}
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {children}
      <section className="signup-section" id="signup">
        <div className="container px-4 px-lg-5">
          {/* <div className="row gx-4 gx-lg-5">
            <div className="col-md-10 col-lg-8 mx-auto text-center">
              <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
              <h2 className="text-white mb-5">Subscribe to receive updates!</h2>

              <form
                className="form-signup"
                id="contactForm"
                action="./email.php"
              >
                <div className="row input-group-newsletter">
                  <div className="col">
                    <input
                      className="form-control"
                      id="emailAddress"
                      type="email"
                      placeholder="Enter email address..."
                      aria-label="Enter email address..."
                      data-sb-validations="required,email"
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      id="submitButton"
                      type="submit"
                    >
                      Notify Me!
                    </button>
                  </div>
                </div>
                <div
                  className="invalid-feedback mt-2"
                  data-sb-feedback="emailAddress:required"
                >
                  An email is required.
                </div>
                <div
                  className="invalid-feedback mt-2"
                  data-sb-feedback="emailAddress:email"
                >
                  Email is not valid.
                </div>
              </form>
            </div>
          </div> */}
          <div className="row gx-4 gx-lg-5">
            <section className="contact-section">
              <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="card py-4 h-100">
                      <div className="card-body text-center">
                        <i className="fas fa-map-marked-alt mb-2 orange"></i>
                        <h4 className="text-uppercase m-0">
                          {t("footer.address")}
                        </h4>
                        <hr className="my-4 mx-auto" />
                        <div className="small text-black-50">
                          {t("footer.location")}
                          <br />
                          {t("footer.location2")}
                          <br />
                          {t("footer.location3")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="card py-4 h-100">
                      <div className="card-body text-center">
                        <i className="fas fa-envelope mb-2 orange"></i>
                        <h4 className="text-uppercase m-0">
                          {t("footer.email")}
                        </h4>
                        <hr className="my-4 mx-auto" />
                        <div className="small text-black-50">
                          <a
                            href="mailto:areus@areus.pt"
                            style={{
                              fontSize: "14px",
                              textDecoration: "none",
                            }}
                          >
                            areus@areus.pt
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="card py-4 h-100">
                      <div className="card-body text-center">
                        <i className="fas fa-mobile-alt mb-2 orange"></i>
                        <h4 className="text-uppercase m-0">
                          {" "}
                          {t("footer.phone")}
                        </h4>
                        <hr className="my-4 mx-auto" />
                        <div className="small text-black-50">
                          <a
                            href="tel:+351221178805"
                            style={{
                              fontSize: "14px",
                              textDecoration: "none",
                            }}
                          >
                            (+351) 221 178 805
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="social d-flex justify-content-center">
                  <a
                    className="mx-2"
                    href="https://www.instagram.com/automationareus/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="mx-2"
                    href="https://www.linkedin.com/company/areus-automation-are-us-lda/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <footer className="footer bg-black small text-center text-white-50">
        <div className="container px-4 px-lg-5">
          Copyright &copy; Areus {new Date().getFullYear()}
        </div>
      </footer>
    </>
  );
};
